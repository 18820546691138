import jwtDecode from "jwt-decode";

export async function decodeToken() {
	const token: any = await getToken();
	return jwtDecode<any>(token);
}

async function getToken() {
	const timeout = () => {
		return new Promise((resolve) => {
			setTimeout(async () => {
				const res = localStorage.getItem('token')
				if (!res) {
					resolve(timeout())
				} else {
					resolve(res);
				}
			}, 1000);
		});
	}
	const token: string = localStorage.getItem('token')
	if (token) {
		return token
	} else {
		return await timeout();
	}
}

export async function userRoles() {
	const token: string = localStorage.getItem('token')
	const auth0Id: string = localStorage.getItem('auth0Id')
	try {
		if (!token || !auth0Id) {
			return 'No token found'
		} else {
			const url = `https://dev-9zt22me9.us.auth0.com/api/v2/users/${auth0Id}/roles`;
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return await response.json()
		}
	} catch (e) {
		console.log(e.message);
	}
}

export function getEnv() {
	return {
    API_RECRUITMENT: process.env.API_RECRUITMENT,
    API_ASSESSMENT: process.env.API_ASSESSMENT,
    HELLOSIGN_CLIENTID: process.env.HELLOSIGN_CLIENTID,
  };
}

export function GlobalConstants() {
	return {
		phoneValidationLength: 12,
	}
}

const {API_RECRUITMENT} = getEnv()
const getConfig=()=>{
	const token: string = localStorage.getItem('token')
	return {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		}
	}
}
export const selectSearchFuncLocation = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/location/${searchValue}`,getConfig())
		.then((response) => response.json())

	return req.map(item => {
		return {
			value: `${item.id}`,
			label: `${item.city}, ${item.state}`,
		}
	})
}

export const selectSearchFuncLocationLimit = async (searchValue) => {
	const req = await fetch(`${API_RECRUITMENT}/api/location/filter?${searchValue}`, getConfig())
					.then((response) => response.json())
	return req.map(item => {
					return {
									value: `${item.id}`,
									label: `${item.city}, ${item.state}`,
									id:item.id
					}
	})
}

export const selectSearchFuncPosition = async (searchValue) => {
	return  await fetch(`${API_RECRUITMENT}/api/position/${searchValue}`, getConfig())
		.then((response) => response.json())
}

export const selectSearchFuncIndustry = async (searchValue) => {
	return  await fetch(`${API_RECRUITMENT}/api/industry/label/${searchValue}`, getConfig())
		.then((response) => response.json())
}

export const selectSearchFuncHRPosition = async (searchValue) => {
	return  await fetch(`${API_RECRUITMENT}/api/recruiter-position/${searchValue}`, getConfig())
		.then((response) => response.json())
}

export const selectSearchFuncDepartment = async (searchValue) => {
	return  await fetch(`${API_RECRUITMENT}/api/department/label/${searchValue}`, getConfig())
		.then((response) => response.json())
}
